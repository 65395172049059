import React, { useState } from "react"
import cn from "classnames"
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image"
import { InView } from "react-intersection-observer"
import ButtonLink from "@components/UI/Buttons/ButtonLink"
import Footer from "@components/UI/Footer"
import {
  BigBlueCircle,
  BigGreenCircle,
  SmallWhiteCircle,
} from "./NotFoundCircles"
import { NodesFooterArrayType } from "@typings/index"
import { NotFoundSectionDataType } from "@typings/requests/404"
import "./404.scss"

interface NotFoundSectionPropsTypes
  extends Omit<NotFoundSectionDataType, "image"> {
  image: IGatsbyImageData
  footerData: NodesFooterArrayType
}

const NotFoundSection: React.FC<NotFoundSectionPropsTypes> = ({
  title,
  description,
  image,
  errorButtonsRedirect,
  footerData,
}) => {
  const [isInView, setIsInView] = useState(false)
  const [isImageLoaded, setIsImageLoaded] = useState(false)

  const onLoadedCarImage = () => setIsImageLoaded(true)

  return (
    <InView
      className="not-found container"
      as="section"
      triggerOnce
      threshold={[0.3]}
      onChange={setIsInView}
    >
      <SmallWhiteCircle isView={isInView} />
      <BigBlueCircle isView={isInView} />
      <BigGreenCircle isView={isInView} />
      <div className="not-found__header-container">
        <h1 className="not-found__title text text--h1">{title}</h1>
        <p className="not-found__description text">{description}</p>
        <ul className="not-found__items list-unstyled">
          {errorButtonsRedirect?.map(item => {
            const isLocalLink = !item.url?.includes("https")
            return (
              <li key={item.id} className="not-found__item">
                <ButtonLink
                  className="not-found__button button--white"
                  isLocal={isLocalLink}
                  href={item.url}
                  label={item.label}
                />
              </li>
            )
          })}
        </ul>
      </div>
      <div
        className={cn("not-found__image-container", {
          "h-in-view": isImageLoaded,
        })}
      >
        <GatsbyImage
          image={image}
          className="not-found__image"
          alt="This page is not found"
          onLoad={onLoadedCarImage}
        />
      </div>
      <div className="not-found__footer-container">
        <Footer className="not-found__footer" {...footerData} />
      </div>
    </InView>
  )
}

export default NotFoundSection
