import React from "react"
import cn from "classnames"
import { Parallax } from "react-scroll-parallax"
import Circle from "@components/Circle"
import { AnimatedCirclePropsTypes } from "@typings/props"

export const SmallWhiteCircle: React.FC<AnimatedCirclePropsTypes> = ({
  isView,
}) => (
  <div
    className={cn("not-found-circle not-found-circle--small-white", {
      "h-in-view": isView,
    })}
  >
    <Parallax>
      <Circle color="#FFFFFF" />
    </Parallax>
  </div>
)

export const BigBlueCircle: React.FC<AnimatedCirclePropsTypes> = ({
  isView,
}) => (
  <div
    className={cn("not-found-circle not-found-circle--big-blue", {
      "h-in-view": isView,
    })}
  >
    <Parallax>
      <Circle color="#440099" isSolid />
    </Parallax>
  </div>
)

export const BigGreenCircle: React.FC<AnimatedCirclePropsTypes> = ({
  isView,
}) => (
  <div
    className={cn("not-found-circle not-found-circle--big-green", {
      "h-in-view": isView,
    })}
  >
    <Parallax>
      <Circle color="#009B3A" isSolid />
    </Parallax>
  </div>
)
